import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from "lodash-es";
import { EnvService } from '../../environment/env.service';
import { Config } from '../../../../config';
import { CMD_GES_PRECO_HEADER } from '../../../../core/model/member/commandes/cmd-ges-preco-header.model';
import { ICommandeSearchResponse, SearchCommandeModel } from '../../../../core/model/member/commandes/search-commande-result.model';
import { catchError, map, tap } from 'rxjs/operators';
import { CmdGesPrecoModel } from '../../../../core/model/member/commandes/cmd-ges-preco.model';
import { CMD_GES_PRECO_Line } from '../../../../core/model/member/commandes/cmd-ges-preco-line.model';
import { FilterCommandeModel } from '../../../../core/model/member/commandes/filter-commande.model';
import { CatalogSupplierModel } from '../../../../core/model/member/commandes/CatalogSupplierModel.model';
import { SendMailCmdModel } from '../../../../core/model/member/commandes/cmd-send-mail-content.model';
import { TrameCmdValide } from '../../../../core/model/member/commandes/trame-commande-valide.model';
import { VueTrameCommandeModel } from '../../../../core/model/member/commandes/vue-trame-commande.model';
import { GRAAL_CENTRE } from '../../../../core/model/common/graal_centre.model';
import { GRAAL_LIBELLE } from '../../../../core/model/common/graal_libelle.model';
import { GesAvoirsValideModel } from '../../../../core/model/member/stock/ges-avoirs-valide.model';
import { VUE_GES_AVOIR } from '../../../../core/model/member/stock/VUE_GES_AVOIR.model';
import { GES_AVOIRS_Line } from '../../../../core/model/member/stock/ges-avoirs-line.model';
import { GES_AVOIR_ENTETE } from '../../../../core/model/member/stock/ges-avoirs-header.model';
import { GES_DES_AVOIR_ENTETE } from '../../../../core/model/member/stock/ges-des-avoirs-header.model';
import { GES_DES_AVOIRS_Line } from '../../../../core/model/member/stock/ges-des-avoirs-line.model';
import { GesDesAvoirsValideModel } from '../../../../core/model/member/stock/ges-des-avoirs-valide.model';
import { VUE_GES_DES_AVOIR } from '../../../../core/model/member/stock/VUE_GES_AVOIR.model copy';
import { ISearchAvoirDemAvoirModel, SearchAvoirDemAvoirModel } from '../../../../core/model/member/stock/search-avoir-dem-avoir.model';
import { FilterAvoirDemAvoirModel } from '../../../../core/model/member/stock/filter-avoir-des-avoir.model';
import * as moment from 'moment';
import { CmdKeyModel } from '../../../../core/model/member/commandes/cmd-key.model';
import { CmdSendTelModel } from '../../../../core/model/member/commandes/cmd-send-tel-model';
import { FilterReceptionModel } from '../../../../core/model/member/stock/filter-reception.model';
import { VUE_STOCK_LIST_REPCEPTION } from 'src/app/core/model/member/stock/VUE_STOCK_LIST_REPCEPTION.model';


@Injectable()
export class CommandesService {

    // url api
    private _urlBaseAPI: string;

    private _urlGetAllCmdHeader: string;

    private _urlSearchCommande: string;

    private _urlGetDetailCommandeIuLetter: string;

    private _urlEnregisterCmd: string;

    private _urlGenererPDF: string;

    private _urlGenererPDFReception: string;

    private _urlPostListCmdAfterEdit: string;
    private _urlPostFilterCommande: string;
    private _urlPostFilterReception: string;

    private _urlPostSendMailPDFCommande: string;
    private _urlPostSendTelPDFCommande: string;   ///tel

    private _urlCreateTrameCmd: string;

    private _urlGetAllTrameCmd: string;

    private _urlGetDetailTrameCmd: string;

    private _urlPostSaveEditTrameCmd: string;

    private _urlPostDeleteTrameCmd: string;

    private _urlGetListCritereFIQ: string;

    private _urlPostSaveEditReception: string;

    private _urlPostSolderCommandeReceptionCalcul: string;

    private _urlGetAllOrigineArtReception: string;

    private _urlGetListAvoir: string;

    private _urlGetListDesAvoir: string;

    private _urlPostSaveEditAvoir: string;

    private _urlPostSaveEditDemAvoir: string;

    private _urlGetDetailAvoir: string;

    private _urlGetDetailDemandeAvoir: string;

    private _urlPostListAvoirAfterEdit: string;

    private _urlPostListDesAvoirAfterEdit: string;

    private _urlPostCreateDesAvoirFromCmd: string;

    private _urlPostCreateAvoirFromCmd: string;

    private _urlSearchNoAvoir: string;

    private _urlSearchNoDemAvoir: string;

    private _urlPostFilterAvoir: string;

    private _urlPostFilterDemAvoir: string;

    private _urlPostDeleteCmd: string;

    private _urlPostGenererPdfDemAvoir: string;

    private _urlPostGenererPdfAvoir: string;

    private _urlPostApurerCmd: string;

    private _urlPostAnnulerReception: string;

    private _urlPostModificationReception: string;

    private _urlGetDetailCommandeByReception: string;

    public ListTrameCmdSubject = new Subject<VueTrameCommandeModel[]>();
    private _listTrameCmd: VueTrameCommandeModel[] = [];

    public TrameCmdDetailSubject = new Subject<VueTrameCommandeModel[]>();
    private _TrameCmdDetail: VueTrameCommandeModel[] = [];

    public ListCmdHeaderSubject = new Subject<CMD_GES_PRECO_HEADER[]>();
    private _listCmdHeader: CMD_GES_PRECO_HEADER[] = [];

    public _cmdDetail: CmdGesPrecoModel;
    public CmdDetailSubject = new Subject<CmdGesPrecoModel>();

    public _avoirDetail: GesAvoirsValideModel;
    public AvoirDetailSubject = new Subject<GesAvoirsValideModel>();

    public _desavoirDetail: GesDesAvoirsValideModel;
    public DesAvoirDetailSubject = new Subject<GesDesAvoirsValideModel>();

    public ListAvoirSubject = new Subject<VUE_GES_AVOIR[]>();
    private _listAvoir: VUE_GES_AVOIR[] = [];

    // observable suppresion line's commande
    public LineCmdDeleteSubject = new Subject<CMD_GES_PRECO_Line>();

    // observable suppresion line's trame commande
    public LineTrameCmdDeleteSubject = new Subject<VueTrameCommandeModel>();

    // observable change fournisseur commande
    public IuFoursubject = new Subject<CatalogSupplierModel>();

    // observable change fournisseur avoir
    public IuFourAvoirsubject = new Subject<CatalogSupplierModel>();

    // observable change mode de saisie cmd
    public ModeSaisieCmdsubject = new Subject<string>();

    // double ligne de cmd meme fournisseur
    public LineCmdSameAddedSubject = new Subject<CMD_GES_PRECO_Line>();


    // observable suppresion line's avoir
    public LineAvoirDeleteSubject = new Subject<GES_AVOIRS_Line>();

    // observable suppresion line's dem avoir
    public LineDesAvoirDeleteSubject = new Subject<GES_DES_AVOIRS_Line>();

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetAllCmdHeader = Config.API_ROUTES.COMMANDES_API.API_GET_ALL_CMD_HEADER;
        this._urlSearchCommande = Config.API_ROUTES.COMMANDES_API.API_GET_SEARCH_COMMANDE;
        this._urlGetDetailCommandeIuLetter = Config.API_ROUTES.COMMANDES_API.API_GET_DETAIL_COMMANDE_IU_LETTER;
        this._urlEnregisterCmd = Config.API_ROUTES.COMMANDES_API.API_POST_ENREGISTRER_COMMANDE;
        this._urlGenererPDF = Config.API_ROUTES.COMMANDES_API.API_POST_GENERER_PDF_COMMANDE;
        this._urlPostListCmdAfterEdit = Config.API_ROUTES.COMMANDES_API.API_POST_CMD_AFTER_EDIT;
        this._urlPostFilterCommande = Config.API_ROUTES.COMMANDES_API.API_POST_FILTER_CMD;
        this._urlPostFilterReception = Config.API_ROUTES.COMMANDES_API.API_POST_FILTER_RECEP;
        this._urlPostSendMailPDFCommande = Config.API_ROUTES.COMMANDES_API.API_POST_SEND_MAIL_CMD;
        this._urlPostSendTelPDFCommande = Config.API_ROUTES.COMMANDES_API.API_POST_SEND_TEL_CMD;
        this._urlCreateTrameCmd = Config.API_ROUTES.COMMANDES_API.API_POST_CREATE_TRAME_CMD;
        this._urlGetAllTrameCmd = Config.API_ROUTES.COMMANDES_API.API_GET_ALL_TRAME_CMD;
        this._urlGetDetailTrameCmd = Config.API_ROUTES.COMMANDES_API.API_GET_DETAIL_TRAME_CMD;
        this._urlPostSaveEditTrameCmd = Config.API_ROUTES.COMMANDES_API.API_POST_SAVE_EDIT_TRAME_CMD;
        this._urlPostDeleteTrameCmd = Config.API_ROUTES.COMMANDES_API.API_POST_DELETE_TRAME_CMD;
        this._urlGetListCritereFIQ = Config.API_ROUTES.COMMANDES_API.API_GET_LIST_CRITERE_FIQ;
        this._urlPostSaveEditReception = Config.API_ROUTES.COMMANDES_API.API_POST_SAVE_EDIT_RECEPTION;
        this._urlGetAllOrigineArtReception = Config.API_ROUTES.COMMANDES_API.API_GET_ALL_ORIGIN_ART_RECEPTION;
        this._urlGetListAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_LIST_AVOIRS;
        this._urlPostSaveEditAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_SAVE_EDIT_AVOIR;
        this._urlGetDetailAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_DETAIL_AVOIR;
        this._urlPostListAvoirAfterEdit = Config.API_ROUTES.COMMANDES_API.API_POST_LIST_AVOIRS_AFTER_EDIT;
        this._urlPostSaveEditDemAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_SAVE_EDIT_DEM_AVOIR;
        this._urlPostListDesAvoirAfterEdit = Config.API_ROUTES.COMMANDES_API.API_POST_LIST_DEM_AVOIRS_AFTER_EDIT;
        this._urlGetListDesAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_LIST_DEM_AVOIRS;
        this._urlGetDetailDemandeAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_DETAIL_DEM_AVOIR;
        this._urlPostSolderCommandeReceptionCalcul = Config.API_ROUTES.COMMANDES_API.API_POST_SOLDER_COMMANDE_RECEPTION_CALCUL;
        this._urlPostCreateDesAvoirFromCmd = Config.API_ROUTES.COMMANDES_API.API_POST_CREATE_DEM_AVOIR_FROM_CMD;
        this._urlPostCreateAvoirFromCmd = Config.API_ROUTES.COMMANDES_API.API_POST_CREATE_AVOIR_FROM_CMD;
        this._urlSearchNoAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_SEARCH_AVOIR_BY_NOBON;
        this._urlSearchNoDemAvoir = Config.API_ROUTES.COMMANDES_API.API_GET_SEARCH_DEM_AVOIR_BY_NOBON;
        this._urlPostFilterAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_FILTER_AVOIR;
        this._urlPostFilterDemAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_FILTER_DEM_AVOIR;
        this._urlPostDeleteCmd = Config.API_ROUTES.COMMANDES_API.API_POST_DELETE_CMD;
        this._urlGenererPDFReception = Config.API_ROUTES.STOCK_API.API_POST_GENERER_PDF_RECEPTION;
        this._urlPostGenererPdfDemAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_GENERER_PDF_DEMAVOIR;
        this._urlPostGenererPdfAvoir = Config.API_ROUTES.COMMANDES_API.API_POST_GENERER_PDF_AVOIR;
        this._urlPostApurerCmd = Config.API_ROUTES.COMMANDES_API.API_POST_APURER_CMD;
        this._urlPostAnnulerReception = Config.API_ROUTES.COMMANDES_API.API_POST_ANNULER_RECEPTION;
        this._urlPostModificationReception = Config.API_ROUTES.COMMANDES_API.API_POST_MODIF_RECEPTION;
        this._urlGetDetailCommandeByReception = Config.API_ROUTES.COMMANDES_API.API_GET_DETAIL_CMD_BY_RECEPTION;

    }


    public EmitterChangeFournisseurAvoir(pFour: CatalogSupplierModel) {
        this.IuFourAvoirsubject.next(_.cloneDeep(pFour));
    }

    public EmitterDetailTrameCmd() {
        if (this._TrameCmdDetail) {
            this.TrameCmdDetailSubject.next(this._TrameCmdDetail.slice());
        }
    }

    public EmitterListAvoir() {
        this.ListAvoirSubject.next(this._listAvoir.slice());
    }

    // event delete line avoir
    public EmitterDeleteLineAvoir(plineAvoir: GES_AVOIRS_Line) {
        this.LineAvoirDeleteSubject.next(plineAvoir);
    }

    // event delete line avoir
    public EmitterDeleteLineDesAvoir(pDeslineAvoir: GES_DES_AVOIRS_Line) {
        this.LineDesAvoirDeleteSubject.next(pDeslineAvoir);
    }


    public EmitterListTrameCmd() {
        if (this._listTrameCmd) {
            this.ListTrameCmdSubject.next(this._listTrameCmd.slice());
        }
    }

    public EmitterListCmdHeader() {
        if (this._listCmdHeader) {
            this.ListCmdHeaderSubject.next(this._listCmdHeader.slice());
        }
    }

    public EmitterChangeFournisseurCmd(pFour: CatalogSupplierModel) {
        this.IuFoursubject.next(_.cloneDeep(pFour));
    }

    public EmitterChangeModeSaisieCmd(pMode: string) {
        this.ModeSaisieCmdsubject.next(pMode);
    }

    public EmitterAddSameCmdHeader(plineCmd: CMD_GES_PRECO_Line) {
        this.LineCmdSameAddedSubject.next(plineCmd);
    }

    // event delete line cmd
    public EmitterDeleteLineCmd(plineCmd: CMD_GES_PRECO_Line) {
        this.LineCmdDeleteSubject.next(plineCmd);
    }

    // event delete line cmd
    public EmitterDeleteLineTrameCmd(plineCmd: VueTrameCommandeModel) {
        this.LineTrameCmdDeleteSubject.next(plineCmd);
    }

    public EmitterCmdDetail() {
        if (this._cmdDetail) {
            this.CmdDetailSubject.next(_.cloneDeep(this._cmdDetail));
        }
    }

    public EmitterCmdAvoir() {
        if (this._avoirDetail) {
            this.AvoirDetailSubject.next(_.cloneDeep(this._avoirDetail));
        }
    }

    public EmitterCmdDesAvoir() {
        if (this._desavoirDetail) {
            this.DesAvoirDetailSubject.next(_.cloneDeep(this._desavoirDetail));
        }
    }

    public InitNewCmdHeader(): CMD_GES_PRECO_HEADER {
        return {
            PRECO_IUCOM: '',
            PRECO_IUFOU: '',
            NOM_FOURNISSEUR: '',
            PRECO_DATE_COM: '',
            PRECO_DATE_LIVRE: '',
            PRECO_DATE_LIVRE_EDIT: '',
            PRECO_LETTRE: 'A',
            PRECO_ETAT: '',
            DATHRMAJ: '',
            USERMAJ: '',
            GESTDEL: '',
            PRECO_IUCLILIV: '',
            NO_BL: '',
            NO_FACTURE: '',
            PRECO_NUM: undefined,
            PRECO_HRE_LIVRE: '',
            PRECO_COMMENT: '',
            PRECO_FLAG_PRINT: '',
            PRECO_ADR_LIV: '',
            PRECO_ADR_LIV_ID: 'CMDLIV',
            PRECO_MARCHE: '',
            PRECO_IUSTOCK: '',
            PRECO_TRAITE: '',
            COMMENT_FIQ: '',
            ENVOI_PDF: '',
            TOTAL: 0,
            PRECO_DATE_LAST_LIVRE: undefined
        }
    }

    public InitNewLineCmd(): CMD_GES_PRECO_Line {
        return {
            PRECO_LIGNUM: -1,
            PRECO_IUCOM: '',
            PRECO_IUFOU: '',
            Preco_fou_libelle: '',
            PRECO_DATE_COM: '',
            PRECO_DATE_LIVRE: '',
            PRECO_DENREE: '',
            preco_denree_lib: '',
            PRECO_QTE_PRECO: '',
            PRECO_QTE_ALIVRER: '',
            PRECO_BON_LIVRE: '',
            PRECO_QTE_LIVRE: '',
            Preco_qte_recu_edit: '',
            PRECO_PRIX: '',
            PRECO_TVA: '',
            PRECO_REMIS: '',
            IsApplyRemis: false,
            IsEditRemis: false,
            PRECO_VALIDE_COM: '1',
            StateLineCmd: '1',
            PRECO_LETTRE: 'A',
            DATHRMAJ: '',
            USERMAJ: '',
            GESTDEL: '',
            PRECO_LIBEL: '',
            PRECO_REF: '',
            PRECO_NOLIG: '000',
            PRECO_FIQ: '',
            PRECO_DLC: '',
            PRECO_TEMP: '',
            PRECO_DATES_CONSO: '',
            PRECO_NUM_LOT: '',
            PRECO_AGREMENT: '',
            PRECO_MARCHE: '',
            PRECO_CODE_MARCHE: '',
            PRECO_EXPORT: '',
            PRECO_PRIX_FACTURE: '',
            PRECO_IUSTOCK: '',
            PRECO_UF: '',
            PRECO_CONDITIO: '',
            preco_iserror_contitio: false,
            FIRSTDATEFAB: '',
            PRECO_IUFIQ: '',
            Iu_Denree: '',
            Egalims: []
        };
    }

    public SearchCommande(filter: { name: string } = { name: '' }, page = 1): Observable<ICommandeSearchResponse> {
        if (filter.name.length < 3) {
            return Observable.of({
                total: this._listCmdHeader.length,
                results: this._listCmdHeader
            });
        }
        else {
            let listCmdTemp = this._listCmdHeader.slice();
            listCmdTemp = listCmdTemp.filter(p => p.PRECO_IUCOM.startsWith(filter.name));
            return Observable.of({
                total: listCmdTemp.length,
                results: listCmdTemp
            });
        }

    }

    /**
    * rechercher commande dans le server
    * @param filter 
    * @param page 
    */
    public SearchCommandeServer(filter: { name: string } = { name: '' }, page = 1, etat?: string): Observable<ICommandeSearchResponse> {

        let urlAPI = this._urlBaseAPI + this._urlSearchCommande + filter.name;

        if (etat) {
            urlAPI = urlAPI + "&state=" + etat;
        }

        if (filter.name.length < 3) {
            return Observable.of({
                total: this._listCmdHeader.length,
                results: this._listCmdHeader
            });
        }

        return this._httpClient.get<ICommandeSearchResponse>(urlAPI)
            .pipe(
                tap((response: ICommandeSearchResponse) => {
                    response.results = response.results.
                        map(cmd => {
                            if (cmd.PRECO_ETAT == '3') {
                                cmd.TOTAL = 0;
                            }
                            return new SearchCommandeModel(cmd.PRECO_IUCOM,
                                cmd.PRECO_IUFOU,
                                cmd.NOM_FOURNISSEUR,
                                cmd.PRECO_DATE_COM = moment(cmd.PRECO_DATE_COM)
                                    .local(false)
                                    .utc(true)
                                    .format('DD/MM/YYYY'),
                                cmd.PRECO_DATE_LIVRE = moment(cmd.PRECO_DATE_LIVRE)
                                    .local(false)
                                    .utc(true)
                                    .format('DD/MM/YYYY'),
                                cmd.PRECO_DATE_LIVRE_EDIT = moment(cmd.PRECO_DATE_LIVRE_EDIT)
                                    .local(false)
                                    .utc(true)
                                    .format('DD/MM/YYYY'),
                                cmd.PRECO_LETTRE,
                                cmd.PRECO_ETAT,
                                cmd.DATHRMAJ,
                                cmd.USERMAJ,
                                cmd.GESTDEL,
                                cmd.PRECO_IUCLILIV,
                                cmd.PRECO_NUM,
                                cmd.NO_BL,
                                cmd.NO_FACTURE,
                                cmd.PRECO_HRE_LIVRE,
                                cmd.PRECO_COMMENT,
                                cmd.PRECO_FLAG_PRINT,
                                cmd.PRECO_ADR_LIV,
                                cmd.PRECO_ADR_LIV_ID,
                                cmd.PRECO_MARCHE,
                                cmd.PRECO_IUSTOCK,
                                cmd.PRECO_TRAITE,
                                cmd.COMMENT_FIQ,
                                cmd.ENVOI_PDF,
                                cmd.TOTAL,
                                cmd.PRECO_DATE_LAST_LIVRE)
                        });
                    return response;
                })
            );

    }


    public GetListCmdHeader(status?: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetAllCmdHeader;

        if (status) {
            urlAPI += "?s=" + status;
        }

        this._httpClient.get<CMD_GES_PRECO_HEADER[]>(urlAPI).subscribe(
            (response: CMD_GES_PRECO_HEADER[]) => {
                // console.log('service ', response)
                this._listCmdHeader = response;
                this.EmitterListCmdHeader();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailCmd(pIuCmd: string, pLetterCmd: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailCommandeIuLetter + 'iucmd=' + pIuCmd + '&cmd=' + pLetterCmd;

        this._httpClient.get<CmdGesPrecoModel>(urlAPI).subscribe(
            (response: CmdGesPrecoModel) => {
                this._cmdDetail = response;
                this.EmitterCmdDetail();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailCmdParReception(pIdReception: number) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailCommandeByReception + '?id=' + pIdReception;

        this._httpClient.get<CmdGesPrecoModel>(urlAPI).subscribe(
            (response: CmdGesPrecoModel) => {
                this._cmdDetail = response;
                this.EmitterCmdDetail();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public EnregistreCommande(pCmdValide: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlEnregisterCmd;
        // console.log('service reg CMD ', pCmdValide)
        try {
            return this._httpClient.post(URI, pCmdValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListCmdReturn: CmdGesPrecoModel[] = ((response.body) as any);
                                return ListCmdReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public SupprimerCommande(precoNum: number) {
        let URI = this._urlBaseAPI + this._urlPostDeleteCmd;

        try {
            return this._httpClient.post(URI, precoNum,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.status;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public EnregistreReceptionMarchandise(pCmdValideReception: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlPostSaveEditReception;

        try {
            return this._httpClient.post(URI, pCmdValideReception,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListCmdReturn: CmdGesPrecoModel[] = ((response.body) as any);
                                return ListCmdReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public SolderCommandeReceptioniCalcul(pCmdValideReception: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlPostSolderCommandeReceptionCalcul;

        try {
            return this._httpClient.post(URI, pCmdValideReception,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let ListCmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return ListCmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPDFCommande(pCmdHeader: CMD_GES_PRECO_HEADER) {
        let URI = this._urlBaseAPI + this._urlGenererPDF;

        try {
            return this._httpClient.post(URI, pCmdHeader,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPdfDemAvoir(pIuDemAvoir: string) {

        let URI = this._urlBaseAPI + this._urlPostGenererPdfDemAvoir;

        try {
            return this._httpClient.post(URI, JSON.stringify(pIuDemAvoir),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPdfAvoir(pIuAvoir: string) {

        let URI = this._urlBaseAPI + this._urlPostGenererPdfAvoir;

        try {
            return this._httpClient.post(URI, JSON.stringify(pIuAvoir),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPDFReception(pCmdHeader: CMD_GES_PRECO_HEADER) {
        let URI = this._urlBaseAPI + this._urlGenererPDFReception;

        try {
            return this._httpClient.post(URI, pCmdHeader,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPDFSendMailCommande(pCmdContentMail: SendMailCmdModel) {
        let URI = this._urlBaseAPI + this._urlPostSendMailPDFCommande;
        try {
            return this._httpClient.post(URI, pCmdContentMail,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'text'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }

    }
    ///tel
    public GenererPDFSendTelCommande(pCmdContentTel: CmdSendTelModel) {
        let URI = this._urlBaseAPI + this._urlPostSendTelPDFCommande;
        try {
            return this._httpClient.post(URI, pCmdContentTel,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'text'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.status;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }

    }

    public GetCommandeAfterEditSync(pCmdEdit: CmdGesPrecoModel[]) {

        let urlAPI = this._urlBaseAPI + this._urlPostListCmdAfterEdit;

        return this._httpClient.post<CMD_GES_PRECO_HEADER[]>(urlAPI, pCmdEdit);
    }

    public GetAvoirsAfterEditSync(pAvoirEdit: GesAvoirsValideModel[]) {

        let urlAPI = this._urlBaseAPI + this._urlPostListAvoirAfterEdit;

        return this._httpClient.post<VUE_GES_AVOIR[]>(urlAPI, pAvoirEdit);
    }

    public GetDemandeAvoirsAfterEditSync(pDesAvoirEdit: GesDesAvoirsValideModel[]) {

        let urlAPI = this._urlBaseAPI + this._urlPostListDesAvoirAfterEdit;

        return this._httpClient.post<VUE_GES_DES_AVOIR[]>(urlAPI, pDesAvoirEdit);
    }


    public GetOrigineArtReceptionSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetAllOrigineArtReception;

        return this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI);
    }


    public GetCommandeCritereFIQSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetListCritereFIQ;

        return this._httpClient.get<GRAAL_CENTRE[]>(urlAPI);
    }

    public FilterCommandeSync(pCmdFilter: FilterCommandeModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostFilterCommande;

        return this._httpClient.post<CMD_GES_PRECO_HEADER[]>(urlAPI, pCmdFilter);
    }

    public FilterReceptionSync(pRecepFilter: FilterReceptionModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostFilterReception;

        return this._httpClient.post<CMD_GES_PRECO_HEADER[]>(urlAPI, pRecepFilter);
    }

    public CreateTrameCommande(pTrameCmdValide: TrameCmdValide) {

        let URI = this._urlBaseAPI + this._urlCreateTrameCmd;

        try {
            return this._httpClient.post(URI, pTrameCmdValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            return response.status
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(404);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public GetListAllTrameCmd() {

        let urlAPI = this._urlBaseAPI + this._urlGetAllTrameCmd;

        this._httpClient.get<VueTrameCommandeModel[]>(urlAPI).subscribe(
            (response: VueTrameCommandeModel[]) => {
                this._listTrameCmd = response;
                this.EmitterListTrameCmd();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetTrameDetailCmd(pNomTrame: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailTrameCmd + 'nomtrame=' + encodeURIComponent(pNomTrame);

        this._httpClient.get<VueTrameCommandeModel[]>(urlAPI).subscribe(
            (response: VueTrameCommandeModel[]) => {
                this._TrameCmdDetail = response;
                this.EmitterDetailTrameCmd();
                //console.log(this._TrameCmdDetail)
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public EnregistreTrameCommande(pTrameCmdValide: VueTrameCommandeModel[]) {
        let URI = this._urlBaseAPI + this._urlPostSaveEditTrameCmd;

        try {
            return this._httpClient.post(URI, pTrameCmdValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(404);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public SupprimerTrameCmd(pIuBon: number, pIuFou: string, pNomTrame: string) {
        let URI = this._urlBaseAPI + this._urlPostDeleteTrameCmd;

        let deletedTrame: VueTrameCommandeModel = {
            BONECO_IU_BON: pIuBon,
            BONECO_IU_FOU: pIuFou,
            NOM_FOURNISSEUR: undefined,
            BONECO_DATEFAB: pNomTrame,
            BONECO_DENREE: undefined,
            NOM_DENREE: undefined,
            BONECO_ATELIER: undefined,
            BONECO_FITECH: undefined,
            BONECO_QTE: undefined,
            CATALOG_PRIX_EUROS: undefined,
            BONECO_UF: undefined,
            DENREE_TVA: undefined,
            CATALOG_REF_EXTERNE: undefined
        };
        try {
            return this._httpClient.post(URI, deletedTrame,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.status;
                            }
                            return undefined;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public InitNewAvoirHeader(): GES_AVOIR_ENTETE {
        return {
            AVOIR_BON: '',
            PRECO_NUM: undefined,
            AVOIR_COMMENT: '',
            AVOIR_DATE_LIVRE: undefined,
            AVOIR_IU: '',
            AVOIR_IUFOU: '',
            AVOIR_LETTRE: 'A',
            AVOIR_STATE: undefined,
            CreatedDate: undefined,
            EditedDate: undefined
        }
    }

    public InitNewlineAvoir(): GES_AVOIRS_Line {
        return {
            AVOIR_BON: '',
            AVOIR_COMMENT: '',
            AVOIR_DATE: '',
            AVOIR_DATE_LIVRE: '',
            AVOIR_DENREE: '',
            AVOIR_IU: '',
            AVOIR_IUFIQ: '',
            AVOIR_IUFOU: '',
            AVOIR_LETTRE: 'A',
            AVOIR_LIG: '000',
            AVOIR_MOTIF: '',
            AVOIR_PRIX: '',
            AVOIR_QTE: '',
            CreatedDate: undefined,
            DATHRMAJ: '',
            EditedDate: undefined,
            GESTDEL: '',
            USERMAJ: '',
            AVOIR_ART_REF: '',
            AVOIR_ART_UF: '',
            AVOIR_QTE_CDE: '',
            AVOIR_QTE_LIVRE: '',
            AVOIR_ART_PRIX_CDE: undefined,
            AVOIR_TVA: ''
        }
    }

    public InitNewDesAvoirHeader(): GES_DES_AVOIR_ENTETE {
        return {
            AVOIR_BON: '',
            PRECO_NUM: undefined,
            AVOIR_COMMENT: '',
            AVOIR_DATE_LIVRE: undefined,
            AVOIR_IU: '',
            AVOIR_IUFOU: '',
            AVOIR_LETTRE: 'A',
            CreatedDate: undefined,
            EditedDate: undefined
        }
    }

    public InitNewDeslineAvoir(): GES_DES_AVOIRS_Line {
        return {
            AVOIR_BON: '',
            AVOIR_COMMENT: '',
            AVOIR_DATE: '',
            AVOIR_DATE_LIVRE: '',
            AVOIR_DENREE: '',
            AVOIR_IU: '',
            AVOIR_IUFIQ: '',
            AVOIR_IUFOU: '',
            AVOIR_LETTRE: 'A',
            AVOIR_LIG: '000',
            AVOIR_MOTIF: '',
            AVOIR_PRIX: '',
            AVOIR_QTE: '',
            CreatedDate: undefined,
            DATHRMAJ: '',
            EditedDate: undefined,
            GESTDEL: '',
            USERMAJ: '',
            AVOIR_ART_REF: '',
            AVOIR_ART_UF: '',
            AVOIR_QTE_CDE: '',
            AVOIR_QTE_LIVRE: '',
            AVOIR_ART_PRIX_CDE: undefined,
            AVOIR_TVA: ''
        }
    }

    public GetListAvoir() {

        let urlAPI = this._urlBaseAPI + this._urlGetListAvoir;

        this._httpClient.get<VUE_GES_AVOIR[]>(urlAPI).subscribe(
            (response: VUE_GES_AVOIR[]) => {
                this._listAvoir = response;

                this.EmitterListAvoir();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetListAvoirSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetListAvoir;

        return this._httpClient.get<VUE_GES_AVOIR[]>(urlAPI).pipe(
            map((res: VUE_GES_AVOIR[]) => {
                res.map(x => {


                });
                res.sort(function (a, b) {
                    return a.AVOIR_DATE_LIVRE < b.AVOIR_DATE_LIVRE ? 1 : a.AVOIR_DATE_LIVRE > b.AVOIR_DATE_LIVRE ? -1 : 0
                });
                return res;
            })
        );
    }

    public GetListDemandeAvoirSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetListDesAvoir;

        return this._httpClient.get<VUE_GES_DES_AVOIR[]>(urlAPI);
    }

    public EnregistreAvoir(pAvoirValide: GesAvoirsValideModel) {

        let URI = this._urlBaseAPI + this._urlPostSaveEditAvoir;

        try {
            return this._httpClient.post(URI, pAvoirValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let ListAvoirReturn: GesAvoirsValideModel = ((response.body) as any);
                                return ListAvoirReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public EnregistreDemAvoir(pDemAvoirValide: GesDesAvoirsValideModel) {

        let URI = this._urlBaseAPI + this._urlPostSaveEditDemAvoir;

        try {
            return this._httpClient.post(URI, pDemAvoirValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let ListDemAvoirReturn: GesDesAvoirsValideModel[] = ((response.body) as any);
                                return ListDemAvoirReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GetDetailAvoir(pIuAvoir: string, pLetterAvoir: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailAvoir + 'iu=' + pIuAvoir + '&letter=' + pLetterAvoir;

        this._httpClient.get<GesAvoirsValideModel>(urlAPI).subscribe(
            (response: GesAvoirsValideModel) => {
                this._avoirDetail = response;
                this.EmitterCmdAvoir();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailDemandeAvoir(pIuAvoir: string, pLetterAvoir: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailDemandeAvoir + 'iu=' + pIuAvoir + '&letter=' + pLetterAvoir;

        this._httpClient.get<GesDesAvoirsValideModel>(urlAPI).subscribe(
            (response: GesDesAvoirsValideModel) => {
                this._desavoirDetail = response;
                this.EmitterCmdDesAvoir();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public CreateDemAvoirFromCmd(pCmdValideReception: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlPostCreateDesAvoirFromCmd;

        try {
            return this._httpClient.post(URI, pCmdValideReception,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let DesAvoirReturn: GesDesAvoirsValideModel = ((response.body) as any);
                                return DesAvoirReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public CreateAvoirFromCmd(pCmdValideReception: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlPostCreateAvoirFromCmd;

        try {
            return this._httpClient.post(URI, pCmdValideReception,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let AvoirReturn: GesAvoirsValideModel = ((response.body) as any);
                                return AvoirReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    /**
* rechercher avoir ou demande d'avoir dans le server
* @param filter 
* @param page 
*/
    public SearchAvoirOrDemAvoirServer(pType: string, filter: { name: string } = { name: '' }, page = 1): Observable<ISearchAvoirDemAvoirModel> {

        let urlAPI = this._urlBaseAPI;

        switch (pType) {
            case "av": {
                urlAPI += this._urlSearchNoAvoir + filter.name;
                break;
            }
            case "dm": {
                urlAPI += this._urlSearchNoDemAvoir + filter.name;
                break;
            }
            default: {
                urlAPI = "";
                break;
            }
        }

        if (urlAPI != "") {
            if (filter.name.length < 3) {
                return Observable.of({
                    total: 0,
                    results: []
                });
            }

            return this._httpClient.get<ISearchAvoirDemAvoirModel>(urlAPI)
                .pipe(
                    tap((response: ISearchAvoirDemAvoirModel) => {
                        response.results = response.results.
                            map(av => new SearchAvoirDemAvoirModel(
                                av.AVOIR_IU,
                                av.AVOIR_LETTRE,
                                av.AVOIR_DATE_LIVRE,
                                av.AVOIR_BON,
                                av.AVOIR_FOU,
                                av.PRECO_IUCOM,
                                av.TOTAL_HT,
                                av.totalTTC
                            ));
                        return response;
                    })
                );
        }
        else {
            return Observable.of({
                total: 0,
                results: []
            });
        }
    }

    public FilterAvoirDemAvoirSync(pType: string, pAvoirFilter: FilterAvoirDemAvoirModel) {

        let urlAPI = this._urlBaseAPI;

        switch (pType) {
            case "av": {
                urlAPI += this._urlPostFilterAvoir
                break;
            }
            case "dm": {
                urlAPI += this._urlPostFilterDemAvoir
                break;
            }
            default: {
                urlAPI = "";
                break;
            }
        }

        if (urlAPI != "") {
            return this._httpClient.post<SearchAvoirDemAvoirModel[]>(urlAPI, pAvoirFilter);
        }
        else {
            return Observable.of([]);
        }
    }

    public ApurerCommande(cmdKey: CmdKeyModel) {
        let URI = this._urlBaseAPI + this._urlPostApurerCmd;

        try {
            return this._httpClient.post(URI, cmdKey,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            return response.status
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(404);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public AnnulerReception(cmdKey: CmdKeyModel) {
        let URI = this._urlBaseAPI + this._urlPostAnnulerReception;

        try {
            return this._httpClient.post(URI, cmdKey,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            return response.status
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(404);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public EnregisterModificationReceptionMarchandise(pCmdValideReception: CmdGesPrecoModel) {
        let URI = this._urlBaseAPI + this._urlPostModificationReception;

        try {
            return this._httpClient.post(URI, pCmdValideReception,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return CmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }
}