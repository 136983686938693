<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-5">
            Sélectionner les Articles à Ajouter à la Commande
        </div>
        <div class="col-md-7">
            <div class="d-flex justify-content-end">
                <button (click)="onAdd()" matTooltip=" {{ 'Add' | translate }}" mat-stroked-button
                    class="button-icon-generic-validate me-3" type="button">
                    <i class="fas fa-check"></i>
                </button>
                <button (click)="onClose()" matTooltip="{{ 'Cancel' | translate }}" mat-stroked-button
                    class="button-icon-generic-cancel" type="button">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <cdk-virtual-scroll-viewport tvsItemSize class="wrapper height-650">
                <table class="table width-100-percent no-border-table table-dialog-art-output" mat-table
                    [dataSource]="dataSourceListStockArt" matSort>
                    <ng-container matColumnDef="select">
                        <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? allToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td class="border-col-table" mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CODE_DENREE">
                        <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            {{ "Code" | translate }}
                        </th>
                        <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                            <span>
                                {{ element.CODE_DENREE }}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="NOM_DENREE">
                        <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            {{ "Article" | translate }}
                        </th>
                        <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                            <div class="text-start ms-2">
                                {{ element.NOM_DENREE }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CATALOG_PRIX_EUROS">
                        <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            PU €
                        </th>
                        <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                            <div class="me-2">
                                {{ element.CATALOG_PRIX_EUROS }}
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ 'edit-row-tab': row.IsEdit === true }"></tr>
                </table>

                <div *ngIf="!IsLoaded">
                    <ng-container *ngFor="let number of [].constructor(8)">
                        <div class="row">
                            <div class="col">
                                <content-loader [Height]="50"></content-loader>
                                <br />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>