import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CMD_GES_PRECO_Line } from '../../../core/model/member/commandes/cmd-ges-preco-line.model';
import { CmdGesPrecoModel } from '../../../core/model/member/commandes/cmd-ges-preco.model';
import { GES_CATALOG_FOUR } from '../../../core/model/member/purchases/denree/ges_catalog_four.model';
import { SupplierModel } from '../../../core/model/member/purchases/supplier/supplier-name.model';
import { SearchArticleModel } from '../../../core/model/member/purchases/denree/search-article-result.model';
import { CommandesService } from '../../../core/services/member/commandes/commandes.service';
import { DenreeService } from '../../../core/services/member/purchages/supplier/denree.service';
import { SupplierService } from '../../../core/services/member/purchages/supplier/supplier.service';
import * as _ from 'lodash-es';
import { CatalogSupplierModel } from '../../../core/model/member/commandes/CatalogSupplierModel.model';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogViewArticleDetailComponent,
  ViewArticleDetailData,
} from '../dialog-view-article-detail/dialog-view-article-detail.component';
import { VUE_GES_CATALOG_FOUR } from '../../../core/model/member/purchases/denree/VUE_GES_CATALOG_FOUR.model';
import { DialogArtOutputSelectSearchComponent, ViewArtOutputSearchData } from '../../stock/article-out/dialog-art-output-select-search/dialog-art-output-select-search.component';
import { VUE_STOCK_SUIVI_ARTICLE } from 'src/app/core/model/member/stock/stock-output-art.model';
import { DialogArtOutputSelectSearchComponentCmd, ViewArtOutputSearchDataCmd } from '../dialog-art-output-select-search/dialog-art-output-select-search.component';

export interface Character {
  name: string;
  created: string;
  edited: string;
  url: string;
  NOM_DENREE: string;
}

function instanceOfCharacter(character: any): character is Character {
  return (
    !!character &&
    typeof character !== 'string' && //
    'NOM_DENREE' in character
  ); // contient clé selectionné
}

export const CharacterSelectionRequiredValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null =>
  !instanceOfCharacter(control?.value) ? { matchRequired: true } : null;

@Component({
  selector: 'EditLineCommandeComponent',
  templateUrl: './edit-line-commande.component.html',
  styleUrls: ['./edit-line-commande.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLineCommandeComponent implements OnInit {
  public show: boolean = false;
  public toggleButton: any = 'Show';

  private _subscriptions: Subscription[] = [];

  public ListSupplierCatalog: CatalogSupplierModel[] = [];

  public RegisterFormLineCommande: FormGroup;
  public submittedRegisterFormLineCommande: boolean = false;

  public isLoadingSearchArt = false;
  public ArticleSearchForm: FormGroup;
  public SelectFormFourControl: FormControl;
  public FormQteArtControl: FormControl;
  public FormPrixArtControl: FormControl;
  public FormDateConsoControl: FormControl;

  public ListResultSearchArticle: SearchArticleModel[] = [];
  //test pop up send selected articles list to parent component
  @Output() newCmdArticles = new EventEmitter<SearchArticleModel[]>();
  @Input() isEgalim: boolean = false;



  public ArticleCatalogFour: VUE_GES_CATALOG_FOUR;

  public IsDisplayEgalim: boolean = false;

  // public CommandInfo: CmdGesPrecoModel;

  public SelectedArtSearch: SearchArticleModel;

  @Input() ModeSaisieChange: string;

  @Input() LineCmdInfoChange: CMD_GES_PRECO_Line;

  @Input() IdxLineCmd: number;

  @Output() ChangeLineCmd = new EventEmitter<CMD_GES_PRECO_Line>();

  @Input() EtatCmd: string;

  public IndexLine: number;

  public ModeSaise: string;

  public LineCmdInfo: CMD_GES_PRECO_Line;

  public MontantTotalLine: string = "0";

  constructor(
    private cdRef: ChangeDetectorRef,
    private _commandesService: CommandesService,
    private _supplierService: SupplierService,
    private _denreeService: DenreeService,
    private _formSearchArt: FormBuilder,
    private _dialog: MatDialog
  ) {
    /**** search article  form */

    this.ArticleSearchForm = this._formSearchArt.group({
      ArticleSearchInput: [null, CharacterSelectionRequiredValidator],
    });


    this._subscriptions.push(
      this.ArticleSearchForm.get('ArticleSearchInput')
        .valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged(), // If previous search is different from current
          filter((value) => typeof value === 'string'), // trigger if value is string to prevent twice fire
          tap(() => { this.isLoadingSearchArt = true; setTimeout(() => this.cdRef.detectChanges()) }),
          switchMap((value) =>
            this._denreeService
              .SearchArticle({ name: value }, 1, this.LineCmdInfo.PRECO_IUFOU)
              .pipe(finalize(() => (this.isLoadingSearchArt = false)))
          )
        )
        .subscribe((resultArticles) => {
          this.ListResultSearchArticle = resultArticles.results;

          // reinit code denréé quand on ne selectionne pas d'article
          this.LineCmdInfo.PRECO_DENREE = '';
          this.ChangeLineCmd.emit(this.LineCmdInfo);

          if (resultArticles.results.length == 0) {
            // reload fourniiseur que si c'est commande global
            if (this.ModeSaisieChange != '0') {
              this.ListSupplierCatalog = [];
              this.SelectFormFourControl.setValue('');
              this.GetAllFournisseur();
              // reinit IUFOU qui avait été séléctionné
              this.LineCmdInfo.PRECO_IUFOU = '';
            }
            this.SelectedArtSearch = null;
            // reset display egalim
            this.IsDisplayEgalim = false;
          }
        })
    );
    /**** end search article  form */

    this.SelectFormFourControl = new FormControl('', Validators.required);
    this.FormQteArtControl = new FormControl('', Validators.required);
    this.FormDateConsoControl = new FormControl('');
    this.FormPrixArtControl = new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$'),
    ]);

    // listener change fournisseur cas edit par fournisseur
    this._subscriptions.push(
      this._commandesService.IuFoursubject.subscribe(
        (result: CatalogSupplierModel) => {
          // set dropdown fournisseur and value line cmd
          this.ListSupplierCatalog = [];
          this.ListSupplierCatalog.splice(
            this.ListSupplierCatalog.length,
            0,
            result
          );
          this.SelectFormFourControl.setValue(result.IU_FOURNISSEUR);
          if (this.LineCmdInfo) {
            this.LineCmdInfo.PRECO_IUFOU = result.IU_FOURNISSEUR;
            this.LineCmdInfo.Preco_fou_libelle = result.NOM_FOURNISSEUR;
            // réactiver la saisie d'article input
            this.ArticleSearchForm.get('ArticleSearchInput').enable();
          }
        }
      )
    );

    // listener change mode de saisie
    this._subscriptions.push(
      this._commandesService.ModeSaisieCmdsubject.subscribe(
        (resultMode: string) => {
          if (resultMode != '0') {
            // load tous fournisseur sync sandbox mode saisie global
            if (this.ListSupplierCatalog.length == 0) {
              this.GetAllFournisseur();
            }
            this.ArticleSearchForm.get('ArticleSearchInput').enable();
          } else {
            // reinit fournisseur car le fournisser sera defini dans le header de commande

            this.ListSupplierCatalog = [];
            this.ArticleSearchForm.get('ArticleSearchInput').disable();
          }
        }
      )
    );
  }

  ngOnInit(): void {
    this.LineCmdInfo = this.LineCmdInfoChange;
    this.FormPrixArtControl.setValue(this.LineCmdInfo.PRECO_PRIX);
    this.FormQteArtControl.setValue(this.LineCmdInfo.PRECO_QTE_ALIVRER);
    this.FormDateConsoControl.setValue(this.LineCmdInfo.PRECO_DATES_CONSO);

    if (this.ModeSaisieChange == '0') {
      // mode saisie par founiseur ou edit commande
      // disable input search article
      this.ArticleSearchForm.get('ArticleSearchInput').disable();

      if (this.LineCmdInfo.PRECO_IUFOU) {
        // ajouter un seul fournisseur dans la liste dropdown
        let selectedSupplier: CatalogSupplierModel = {
          CODE_FOURNISSEUR: '',
          Code_Denree: '',
          IU_FOURNISSEUR: this.LineCmdInfo.PRECO_IUFOU,
          NOM_FOURNISSEUR: this.LineCmdInfo.Preco_fou_libelle,
          E_MAIL: '',
          Prix_Denree: '',
          FOU_EGALIM: '',
        };
        // réactiver saisie acticle si le fournisseur a été sélectionné
        this.ArticleSearchForm.get('ArticleSearchInput').enable();
        this.ListSupplierCatalog.splice(this.ListSupplierCatalog.length, 0, selectedSupplier);
        this.SelectFormFourControl.setValue(selectedSupplier.IU_FOURNISSEUR);

        // cas edit commande, set nom d'article dans le champs de recherche
        if (this.LineCmdInfo.PRECO_DENREE) {
          // import catalog fournisseur
          if (
            this.LineCmdInfo.Preco_fou_libelle &&
            this.LineCmdInfo.preco_denree_lib
          ) {
            // this.SelectedArtSearch.CODE_DENREE = this.LineCmdInfo.PRECO_DENREE;
            // this.SelectedArtSearch.NOM_DENREE = this.LineCmdInfo.preco_denree_lib;
            // this.SelectedArtSearch.NOM_FOURNISSEUR = this.LineCmdInfo.Preco_fou_libelle;
            // calcul montant line 
            this.CalculMontantLigne();
          } else {
            // edit normal
            this._denreeService
              .SearchArticle({ name: this.LineCmdInfo.PRECO_DENREE }, 1)
              .subscribe((resultArticles) => {
                this.SelectedArtSearch = resultArticles.results[0];
                this.LineCmdInfo.Iu_Denree = this.SelectedArtSearch.IU_DENREE;
                this.ArticleSearchForm.controls['ArticleSearchInput'].setValue(this.SelectedArtSearch);
                this.ArticleSearchForm.controls['ArticleSearchInput'].disable();
                // calcul montant line 
                this.CalculMontantLigne();
              });
          }
        }
      }
    }
    else {

      if (this.LineCmdInfo.PRECO_DENREE) {
        // commande depuis trame global
        this._denreeService.SearchArticle({ name: this.LineCmdInfo.PRECO_DENREE }, 1)
          .subscribe((resultArticles) => {
            this.SelectedArtSearch = resultArticles.results[0];
            this.LineCmdInfo.Iu_Denree = this.SelectedArtSearch.IU_DENREE;
            this.ArticleSearchForm.controls['ArticleSearchInput'].setValue(this.SelectedArtSearch);
            this.ArticleSearchForm.controls['ArticleSearchInput'].disable();
            let selectedSupplier: CatalogSupplierModel = {
              CODE_FOURNISSEUR: '',
              Code_Denree: '',
              IU_FOURNISSEUR: this.LineCmdInfo.PRECO_IUFOU,
              NOM_FOURNISSEUR: this.LineCmdInfo.Preco_fou_libelle,
              E_MAIL: '',
              Prix_Denree: '',
              FOU_EGALIM: '',
            };

            // this.ListSupplierCatalog.splice(this.ListSupplierCatalog.length, 0, selectedSupplier);

            this._denreeService
              .GetArtCatalogFourSync(this.SelectedArtSearch.CODE_DENREE)
              .subscribe((ListArtCatalogResponse: VUE_GES_CATALOG_FOUR[]) => {
                if (ListArtCatalogResponse) {

                  this.ListSupplierCatalog = [];
                  // contruction nouveau fournisseur avec prix article affiché
                  ListArtCatalogResponse.forEach((x) => {
                    let newCatalogSupplier: CatalogSupplierModel = {
                      CODE_FOURNISSEUR: '',
                      Code_Denree: x.CATALOG_DENREE,
                      IU_FOURNISSEUR: x.CATALOG_IUFOU,
                      NOM_FOURNISSEUR: x.FOU_NOM,
                      E_MAIL: '',
                      Prix_Denree: x.CATALOG_PRIX_EUROS,
                      FOU_EGALIM: x.FOU_EGALIM,
                    };
                    this.ListSupplierCatalog = [
                      ...this.ListSupplierCatalog,
                      newCatalogSupplier,
                    ];
                  });
                  this.LineCmdInfo.PRECO_REF = ListArtCatalogResponse.find(p => p.CATALOG_IUFOU == selectedSupplier.IU_FOURNISSEUR)?.CATALOG_REF_EXTERNE;
                  this.SelectFormFourControl.setValue(selectedSupplier.IU_FOURNISSEUR);
                  // calcul montant line 
                  this.CalculMontantLigne();
                }
              })

          });
      }
      else {
        // load tous fournisseur sync sandbox mode saisie global
        if (this.ListSupplierCatalog.length == 0) {
          this.GetAllFournisseur();
        }
      }

    }

    /*** call edit line cmd here to avoid input changed value qte and prix*/

    this.GesSaisieLineCmd();


  }

  public GetAllFournisseur() {
    this._subscriptions.push(
      this._supplierService
        .GetListSupplierActifSync()
        .subscribe((ListSupplierCatalogResult: SupplierModel[]) => {
          if (ListSupplierCatalogResult) {
            ListSupplierCatalogResult.forEach((x) => {
              let newCatalogSupplier: CatalogSupplierModel = {
                CODE_FOURNISSEUR: x.CODE_FOURNISSEUR,
                Code_Denree: '',
                IU_FOURNISSEUR: x.IU_FOURNISSEUR,
                NOM_FOURNISSEUR: x.NOM_FOURNISSEUR,
                E_MAIL: x.E_MAIL,
                Prix_Denree: '',
                FOU_EGALIM: '',
              };
              this.ListSupplierCatalog = [
                ...this.ListSupplierCatalog,
                newCatalogSupplier,
              ];
            });
          }
        })
    );
  }

  //pop up articles list
  public OpenSearchListArt() {
    const ArtDialogDataCmd = new ViewArtOutputSearchDataCmd(this.ListResultSearchArticle);

    const dialogRef = this._dialog.open(DialogArtOutputSelectSearchComponentCmd, {
      width: '100%',
      height: '100%',
      data: ArtDialogDataCmd,
      panelClass: 'panel-view-dialog-art-detail',
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .subscribe((dialogResultSelectedOutputArticle) => {
        if (dialogResultSelectedOutputArticle) {
          if (dialogResultSelectedOutputArticle.isClose) {
            if (dialogResultSelectedOutputArticle.listSelectedArt.length != 0) {
              //send selected articles list to parent component
              this.newCmdArticles.emit(dialogResultSelectedOutputArticle.listSelectedArt);
            }
          }
        }
      });

    return dialogRef;
  }

  /***
   * load new line cmd
   *
   */
  ngOnChanges(changes: SimpleChanges) {
    // select article by search node article's tree
    // if (changes.LineCmdInfoChange) {
    //   if (changes.LineCmdInfoChange.currentValue) {
    //     this.LineCmdInfo = changes.LineCmdInfoChange.currentValue;

    //     if (this.LineCmdInfo.PRECO_IUFOU) {
    //       let selectedSupplier = this._supplierService.InitNewSupplier();
    //       selectedSupplier.IU_FOURNISSEUR = this.LineCmdInfo.PRECO_IUFOU;
    //       selectedSupplier.NOM_FOURNISSEUR = this.LineCmdInfo.Preco_fou_libelle;
    //       this.ListSupplierCatalog.splice(this.ListSupplierCatalog.length, 0, selectedSupplier);
    //       this.SelectFormFourControl.setValue(selectedSupplier.IU_FOURNISSEUR);
    //     }
    //   }
    // }

    if (changes.IdxLineCmd) {
      this.IndexLine = changes.IdxLineCmd.currentValue + 1;
    }

    if (changes.ModeSaisieChange) {
      this.ModeSaise = changes.ModeSaisieChange.currentValue;
    }
  }

  /**
   * Chercher un article
   *
   */
  public onSubmitRechercherArticle() {
    let nameArt = this.ArticleSearchForm.get('ArticleSearchInput').value;

    if (nameArt && typeof nameArt === 'string') {
      if (this.ListResultSearchArticle.length != 0) {
        this.OpenSearchListArt();//open pop up on enter
      }
    }
  }

  public displayFn(art: SearchArticleModel) {
    if (art) {
      return art.NOM_DENREE;
    }
  }

  /**
   * Montant total de la ligne
   * @param prixUni
   * @returns
   */
  public CalculMontantLigne(): string {
    if (this.LineCmdInfo && this.LineCmdInfo.PRECO_QTE_ALIVRER) {
      this.MontantTotalLine = (
        Number(this.LineCmdInfo.PRECO_PRIX) *
        Number(this.LineCmdInfo.PRECO_QTE_ALIVRER)
      ).toString();
    }
    else {
      this.MontantTotalLine = "0";
    }
    //console.log(this.MontantTotalLine);
    return this.MontantTotalLine;
  }

  /**
   * Gestion saisie line cmd
   */
  public GesSaisieLineCmd() {
    // quantité
    this._subscriptions.push(
      this.FormQteArtControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((val) => {
          if (!isNaN(Number(val.replace(',', '.')))) {
            this.LineCmdInfo.PRECO_QTE_ALIVRER = val.replace(',', '.');
            // controler la saisie de quantité, la quantité doit etre multiple par Cond si existe
            if (this.LineCmdInfo.PRECO_CONDITIO) {
              if (
                Number(this.LineCmdInfo.PRECO_QTE_ALIVRER) %
                Number(this.LineCmdInfo.PRECO_CONDITIO) ==
                0
              ) {
                this.LineCmdInfo.preco_iserror_contitio = false;
              } else {
                // le 31/01/22 demande de SCA, enlever le controle de condition
                this.LineCmdInfo.preco_iserror_contitio = false;
              }
            }
          } else {
            this.LineCmdInfo.PRECO_QTE_ALIVRER = '';
          }
          this.ChangeLineCmd.emit(this.LineCmdInfo);
          this.CalculMontantLigne();
        })
    );
    // prix
    this._subscriptions.push(
      this.FormPrixArtControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((val) => {
          if (!isNaN(Number(val.replace(',', '.')))) {
            this.LineCmdInfo.PRECO_PRIX = val.replace(',', '.');
          } else {
            this.LineCmdInfo.PRECO_PRIX = '';
          }
          this.ChangeLineCmd.emit(this.LineCmdInfo);
          this.CalculMontantLigne();
        })
    );
    // date conso
    this._subscriptions.push(
      this.FormDateConsoControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((val) => {
          console.log('date conso ', val)
          this.LineCmdInfo.PRECO_DATES_CONSO = val;
          this.ChangeLineCmd.emit(this.LineCmdInfo);
        })
    );
  }

  // si on trouve le nom article, on clique sur la liste des noms article proposée pour ses détails
  public ClickOptionSearchArticle(event: any, value: any) {
    if (value) {

      this.SelectedArtSearch = value;
      // console.log('article choisi ', this.SelectedArtSearch);
      this.ListResultSearchArticle = [];//empty search list
      this.LineCmdInfo.PRECO_DENREE = this.SelectedArtSearch.CODE_DENREE;
      this.LineCmdInfo.Iu_Denree = this.SelectedArtSearch.IU_DENREE;
      this.LineCmdInfo.PRECO_TVA = this.SelectedArtSearch.DENREE_TVA;
      if (this.LineCmdInfo.PRECO_IUFOU != '') {
        //console.log(this.LineCmdInfo.PRECO_IUFOU != '');
        // get prix article seul catalog fournisseur sandbox and MAJ prix article saisi
        this._subscriptions.push(
          this._denreeService
            .GetCataLogFournisseurSync(
              this.SelectedArtSearch.CODE_DENREE,
              this.LineCmdInfo.PRECO_IUFOU
            )
            .subscribe((ArtCatalogResponse: VUE_GES_CATALOG_FOUR) => {
              if (ArtCatalogResponse) {
                this.ArticleCatalogFour = ArtCatalogResponse;
                if (ArtCatalogResponse.CATALOG_PRIX_EUROS) {
                  this.LineCmdInfo.PRECO_PRIX = Number(
                    ArtCatalogResponse.CATALOG_PRIX_EUROS.replace(',', '.')
                  ).toString();
                }
                this.FormPrixArtControl.setValue(this.LineCmdInfo.PRECO_PRIX);
                this.LineCmdInfo.PRECO_UF =
                  ArtCatalogResponse.CATALOG_UF_PRESENT;
                this.LineCmdInfo.PRECO_REF =
                  ArtCatalogResponse.CATALOG_REF_EXTERNE;
                this.LineCmdInfo.PRECO_CONDITIO =
                  ArtCatalogResponse.CATALOG_CONDITIO;
                if (ArtCatalogResponse.FOU_EGALIM == '1') {
                  this.IsDisplayEgalim = true;
                } else {
                  this.IsDisplayEgalim = false;
                }
                // send to parent cmd
                this.ChangeLineCmd.emit(this.LineCmdInfo);
              }
            })
        );
      } else {
        // get article list catalog fournisseur avec prix sandbox and MAJ prix article saisi
        this._subscriptions.push(
          this._denreeService
            .GetArtCatalogFourSync(this.SelectedArtSearch.CODE_DENREE)
            .subscribe((ListArtCatalogResponse: VUE_GES_CATALOG_FOUR[]) => {
              if (ListArtCatalogResponse) {
                this.ListSupplierCatalog = [];
                // contruction nouveau fournisseur avec prix article affiché
                ListArtCatalogResponse.forEach((x) => {
                  let newCatalogSupplier: CatalogSupplierModel = {
                    CODE_FOURNISSEUR: '',
                    Code_Denree: x.CATALOG_DENREE,
                    IU_FOURNISSEUR: x.CATALOG_IUFOU,
                    NOM_FOURNISSEUR: x.FOU_NOM,
                    E_MAIL: '',
                    Prix_Denree: x.CATALOG_PRIX_EUROS,
                    FOU_EGALIM: x.FOU_EGALIM,
                  };
                  this.ListSupplierCatalog = [
                    ...this.ListSupplierCatalog,
                    newCatalogSupplier,
                  ];
                });
                // send to parent cmd
                this.ChangeLineCmd.emit(this.LineCmdInfo);
              }
            })
        );
      }

    }
  }

  public OpenDetailArticle(pCodeArt: string, pIuDenree: string) {
    if (pCodeArt && pIuDenree) {
      // console.log('IU_DENREE : ' + this.SelectedArtSearch.IU_DENREE);
      // console.log('CODE_DENREE : ' + this.SelectedArtSearch.CODE_DENREE);
      const IsPdfdialog = this.DialogViewArticleDetail(
        pCodeArt,
        pIuDenree
      );
      IsPdfdialog.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
        }
      });
    }
  }

  public DialogViewArticleDetail(pCodeArt: string, pIuArt: string) {
    const ArtDialogData = new ViewArticleDetailData(pCodeArt, pIuArt);

    const dialogRef = this._dialog.open(DialogViewArticleDetailComponent, {
      width: '100%',
      data: ArtDialogData,
      disableClose: false,
      panelClass: 'panel-view-dialog-art-detail',
    });

    return dialogRef;
  }

  /**
   * Chercher article dans catalog fournisseur
   * @param event id four
   */
  public onFournisseurChange(event: any) {
    //console.log(event);

    // reset display egalim
    this.IsDisplayEgalim = false;

    // set selected fournisseur
    this.LineCmdInfo.PRECO_IUFOU = event.value;
    this.LineCmdInfo.Preco_fou_libelle = this.ListSupplierCatalog.find(
      (x) => x.IU_FOURNISSEUR == event.value
    ).NOM_FOURNISSEUR;

    // set display egalim
    this.ListSupplierCatalog.find((x) => x.IU_FOURNISSEUR == event.value)
      ?.FOU_EGALIM == '1'
      ? (this.IsDisplayEgalim = true)
      : (this.IsDisplayEgalim = false);

    if (this.ModeSaisieChange == '0') {
      // si c'est mode de saisie par fournisseur, on bloque fournisseur selectionné dans la liste
      // set un seul fournisseur dans la liste dropdown
      // ajouter un seul fournisseur dans la liste dropdown
      let selectedSupplier: CatalogSupplierModel = {
        CODE_FOURNISSEUR: '',
        Code_Denree: '',
        IU_FOURNISSEUR: event.value,
        NOM_FOURNISSEUR: this.ListSupplierCatalog.find(
          (x) => x.IU_FOURNISSEUR == event.value
        ).NOM_FOURNISSEUR,
        E_MAIL: this.ListSupplierCatalog.find(
          (x) => x.IU_FOURNISSEUR == event.value
        ).E_MAIL,
        FOU_EGALIM: this.ListSupplierCatalog.find(
          (x) => x.IU_FOURNISSEUR == event.value
        ).FOU_EGALIM,
        Prix_Denree: '',
      };
      this.ListSupplierCatalog = []; // delete all catalog four
      this.ListSupplierCatalog.splice(
        this.ListSupplierCatalog.length,
        0,
        selectedSupplier
      ); // add 1 founr
      this.SelectFormFourControl.setValue(selectedSupplier.IU_FOURNISSEUR);

      this.ChangeLineCmd.emit(this.LineCmdInfo);
    }

    if (this.SelectedArtSearch) {
      // get prix article catalog fournisseur sandbox and MAJ article saisi
      this._subscriptions.push(
        this._denreeService
          .GetCataLogFournisseurSync(
            this.SelectedArtSearch.CODE_DENREE,
            event.value
          )
          .subscribe((ArtCatalogResponse: VUE_GES_CATALOG_FOUR) => {
            if (ArtCatalogResponse) {
              this.ArticleCatalogFour = ArtCatalogResponse;
              if (ArtCatalogResponse.CATALOG_PRIX_EUROS) {
                this.LineCmdInfo.PRECO_PRIX = Number(
                  ArtCatalogResponse.CATALOG_PRIX_EUROS.replace(',', '.')
                ).toString();
              }
              this.FormPrixArtControl.setValue(this.LineCmdInfo.PRECO_PRIX);
              this.LineCmdInfo.PRECO_UF = ArtCatalogResponse.CATALOG_UF_PRESENT;
              this.LineCmdInfo.PRECO_REF =
                ArtCatalogResponse.CATALOG_REF_EXTERNE;
              this.LineCmdInfo.PRECO_CONDITIO =
                ArtCatalogResponse.CATALOG_CONDITIO;
              this.LineCmdInfo.Preco_fou_libelle =
                this.ListSupplierCatalog.find(
                  (p) => p.IU_FOURNISSEUR == event.value
                ).NOM_FOURNISSEUR;
              if (ArtCatalogResponse.FOU_EGALIM == '1') {
                this.IsDisplayEgalim = true;
              } else {
                this.IsDisplayEgalim = false;
              }
              // send to parent cmd
              this.ChangeLineCmd.emit(this.LineCmdInfo);
            }
          })
      );
    }
  }

  /**
   * Supprimer une ligne de commande
   */
  public DeleteLineCmd(): void {
    this._commandesService.EmitterDeleteLineCmd(this.LineCmdInfo);
  }

  /***
   * not used clone line
   * Ajouter une line cmd avec le meme fournisseur
   */
  // public AddLineFourCmd(): void {
  //   let LineCopy = _.cloneDeep(this.LineCmdInfo);
  //   LineCopy.PRECO_PRIX = "";
  //   LineCopy.PRECO_QTE_ALIVRER = "";
  //   this._commandesService.EmitterAddSameCmdHeader(LineCopy);

  //   console.log(this.ModeSaise);

  // }

  div1Toggle() {
    this.show = !this.show;

    if (this.show) this.toggleButton = 'Hide';
    else this.toggleButton = 'Show';

    return;
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
