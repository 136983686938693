import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockService } from '../../../core/services/member/stock/stock.service';
import { VUE_STOCK_SUIVI_ARTICLE } from '../../../core/model/member/stock/stock-output-art.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { SelectionModel } from '@angular/cdk/collections';
import { SearchArticleModel } from 'src/app/core/model/member/purchases/denree/search-article-result.model';


export class ViewArtOutputSearchDataCmd {
  constructor(public ListSearchArtOut?: SearchArticleModel[]) { }
}



@Component({
  selector: 'app-dialog-art-output-select-search',
  templateUrl: './dialog-art-output-select-search.component.html',
  styleUrls: ['./dialog-art-output-select-search.component.css']
})
export class DialogArtOutputSelectSearchComponentCmd implements OnInit {

  public displayedColumns: string[] = [
    'select',
    'CODE_DENREE',
    'NOM_DENREE',
    'CATALOG_PRIX_EUROS',

  ];


  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSourceListStockArt: any = new TableVirtualScrollDataSource([]);

  public IsLoaded: boolean = false;

  public selection = new SelectionModel<SearchArticleModel>(true, []);

  constructor(
    public dialogRef: MatDialogRef<DialogArtOutputSelectSearchComponentCmd>,
    @Inject(MAT_DIALOG_DATA) public SearchArtData: ViewArtOutputSearchDataCmd,
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      if (this.SearchArtData && this.SearchArtData.ListSearchArtOut) {
        // console.log(this.SearchArtData.ListSearchArtOut)

        this.dataSourceListStockArt.data = this.SearchArtData.ListSearchArtOut;

        this.dataSourceListStockArt.sort = this.sort;

        this.IsLoaded = true;
      }
    }, 300);

  }


  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceListStockArt.data.length;
    return numSelected === numRows;
  }


  public allToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceListStockArt.data.forEach(row => this.selection.select(row));
  }

  public onClose() {
    this.dialogRef.close({ isClose: true, listSelectedArt: [] });
  }

  public onAdd() {
    if (this.selection && this.selection.selected.length != 0) {
      // console.log(this.selection.selected)
      this.dialogRef.close({ isClose: true, listSelectedArt: this.selection.selected });
    }
  }


}
