<form class="general-form px-2" id="editCommandes" [formGroup]="RegisterFormEditCommande"
  (ngSubmit)="onSubmitRegisterFormEditCommande()">
  <!---Header -->
  <div
    class="container-fluid px-2 py-2 mb-2 min-height-header border-radius-div margin-bottom-mobile background-color-header-entry align-items-center">
    <!-- first row -->
    <div class="row justify-content-around py-2">
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "OrderType" | translate }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <mat-select (selectionChange)="onSelectSaisieModeChange($event)" [(value)]="SelectedOptSaisie">
                <mat-option *ngFor="let opt of OptSaisie" [value]="opt.id" class="font-size-select">
                  {{ opt.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <div class="d-flex align-items-center">
            <label class="font-weight-700 header-text-color white-space-nowrap">{{ "Supplier" | translate }}</label>
            <mat-icon *ngIf="this.ListSupplierCatalog.length == 1" (click)="ConsultFournDetail()"
              class="customIconSize ps-2 survol">visibility</mat-icon>
          </div>

          <div class="d-flex input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <mat-select [formControl]="SelectFormFourControl" (selectionChange)="onFournisseurChange($event)">
                <!-- <mat-option>None</mat-option> -->
                <mat-option *ngFor="let four of ListSupplierCatalog" [value]="four.IU_FOURNISSEUR"
                  class="font-size-select">
                  <span>{{ four.NOM_FOURNISSEUR }} </span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="SelectFormFourControl.hasError('required')">
                <div>
                  {{ "SelectASupplier" | translate }}
                </div>
              </mat-error>
              <mat-hint>{{ SelectFormFourControl.value?.IU_FOURNISSEUR }}
              </mat-hint>
              <mat-icon class="refresh-fou" *ngIf="SelectedOptSaisie == '0' && !isConsult"
                (click)="RefreshOnFou($event)" class="icon-display" matSuffix>
                <div class="refresh-size">refresh</div>
              </mat-icon>
            </mat-form-field>
          </div>
          <div *ngIf="
              SelectFormFourControl.value === '' &&
              SelectFormFourControl.hasError('required')
            "></div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "DateOfOrder" | translate
            }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <input matInput [matDatepicker]="pickerDateCmd" formControlName="DateCmd" placeholder="dd/mm/yyyy" />
              <mat-datepicker-toggle matSuffix [for]="pickerDateCmd"></mat-datepicker-toggle>
              <mat-datepicker #pickerDateCmd></mat-datepicker>
              <mat-error *ngIf="f.DateCmd.errors?.required">
                <span class="error-input">{{ "Date" | translate }}
                  {{ "Required" | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "DateOfDelivery" | translate
            }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <input matInput [matDatepicker]="pickerDateLivr" formControlName="DateLivr" placeholder="dd/mm/yyyy"
                (dateChange)="ChangeDateLivreSelected('change', $event)" />
              <mat-datepicker-toggle matSuffix [for]="pickerDateLivr"></mat-datepicker-toggle>
              <mat-datepicker #pickerDateLivr></mat-datepicker>
              <mat-error *ngIf="f.DateLivr.errors?.required">
                <span class="error-input">{{ "Date" | translate }}
                  {{ "Required" | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <!-- second row -->
    <div class="row justify-content-around">
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "Order" | translate }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <mat-select formControlName="LetterCmdCtrl" (selectionChange)="onSelectLetterCmdChange($event)">
                <mat-option *ngFor="let alpha of ListLettersCmd" [value]="alpha.IdSelect" class="font-size-select">
                  {{ alpha.ValueSelect }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "BillingAddress" | translate
            }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <mat-select formControlName="AdrFacIdCtrl" (selectionChange)="onSelectAdrFac($event)">
                <mat-option *ngFor="let fac of ListAdrFac" [value]="fac.LIB_Id" class="font-size-select">
                  {{ fac.PARALIB }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <label class="font-weight-700 header-text-color white-space-nowrap">{{ "DeliveryAddress" | translate
            }}</label>
          <div class="input-filter-white">
            <mat-form-field class="input-size-select" appearance="fill">
              <mat-select formControlName="AdrLivIdCtrl" (selectionChange)="onSelectAdrLivraison($event)">
                <mat-option *ngFor="let liv of ListAdrLivraison" [value]="liv.LIB_Id" class="font-size-select">
                  {{ liv.PARALIB }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <div class="mt-3" [ngClass]="!IsEdit ? 'display-none-actions' : ''">
            <button class="button-action-color-blue font-size-button-action" mat-button [matMenuTriggerFor]="menu"
              type="button">
              {{ "Actions" | translate }}
              <i class="fas fa-chevron-down"></i>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" style="display: none">
                {{ "LastCurrentSupplierOrder" | translate }}
              </button>
              <button mat-menu-item type="button" (click)="ImportCatalogFourn()"
                *ngIf="ListSupplierCatalog.length == 1 && SelectedOptSaisie == '0'">
                {{ "ImportCatalogueSupplier" | translate }}
              </button>
              <button mat-menu-item type="button" (click)="CreateTrameCmdGlobal()" *ngIf="SelectedOptSaisie == '1'">
                {{ 'AddToCommandFrames' | translate }}
              </button>
              <!-- <button mat-menu-item
                *ngIf="CommandInfo && CommandInfo.CmdHeader.PRECO_ETAT =='0' &&  CommandInfo.CmdHeader.PRECO_IUCOM != ''"
                type="button">
                Créer un avoir de cette commande
              </button>
              <button mat-menu-item
                *ngIf="CommandInfo && CommandInfo.CmdHeader.PRECO_ETAT =='0' &&  CommandInfo.CmdHeader.PRECO_IUCOM != ''"
                type="button">
                Créer une demande d'avoir de cette commande
              </button> -->
              <button mat-menu-item type="button">
                {{ "MergeTheCurrentOrderWithAnotherPreorder" | translate }}
              </button>
              <!-- <button mat-menu-item type="button">
                  Fusionne des Denrées en Préco à la Commande Courante
                </button>
                <button mat-menu-item type="button">
                  Fusionne toutes les Précos en Cours pour le Fournisseur Courant
                </button>
                <button mat-menu-item>
                  Fusionne les Denrées en Commande à la Commande Courante
                </button> -->
            </mat-menu>
          </div>

          <span class="btn-space"></span>
          <!-- <button mat-stroked-button class="button-generic-action-6cb9c6" type="button"> -->
          <!-- <img
                  src="../../../../assets/images/icons-commande/imprimer.svg"
                  alt="printer"
                /> -->
          <!-- <i class="fas fa-print"></i> -->
          <!-- </button>
                  &nbsp; &nbsp; -->
          <div *ngIf="!IsEdit">
            <button (click)="OpenGenererPDF()" class="button-round round-button-icon"
              matTooltip="{{ 'PDF' | translate }}" type="button" matTooltipPosition="above" mat-mini-fab>
              <img class="width-30" src="../../../../assets/images/icons-commande/export-pdf-bleu.svg" />
            </button>

            <button (click)="OpenSendMailCmd()" class="button-round round-button-icon"
              matTooltip="{{ 'Email' | translate }}" matTooltipPosition="above" mat-mini-fab type="button">
              <img class="width-30" src="../../../../assets/images/icons-commande/email-bleu-93.svg" />
            </button>
            <button *ngIf="
                CommandInfo &&
                CommandInfo.CmdHeader.PRECO_ETAT != '1' &&
                CommandInfo.CmdHeader.PRECO_ETAT != '9' &&
                CommandInfo.CmdHeader.PRECO_ETAT != '3' &&
                !isImportCatalog
              " (click)="OpenTelCmd()" class="button-round round-button-icon" matTooltip="{{ 'Call' | translate }}"
              matTooltipPosition="above" mat-mini-fab type="button">
              <img class="width-30" src="../../../../assets/images/icons-commande/telephone.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- end Header------------------- -->

  <div class="overflow-auto">
    <div class="container-fluid" #linecmdform style="position: relative">
      <!-- ************************** line commande ****************************-->
      <ng-container *ngIf="isLoadedDetailCmd; else loadingDetailCmd">
        <ng-container *ngIf="CommandInfo">
          <div class="container-fluid p-0" *ngFor="
              let linecmd of CommandInfo.CmdLine;
              let i = index;
              let first = first;
              let last = last
            ">
            <EditLineCommandeComponent [LineCmdInfoChange]="linecmd" [IdxLineCmd]="i"
              [EtatCmd]="CommandInfo.CmdHeader.PRECO_ETAT" [ModeSaisieChange]="SelectedOptSaisie"
              (ChangeLineCmd)="ChangeFromLineCmd($event)" [IsDisplayEgalim]="isEgalim"
              (newCmdArticles)="addLinesCmd($event)">
            </EditLineCommandeComponent>
            <!-- <hr *ngIf="!last" /> -->
          </div>
        </ng-container>
      </ng-container>
      <ng-template #loadingDetailCmd>
        <ng-container *ngFor="let number of [].constructor(3)">
          <div class="row">
            <div class="col">
              <content-loader [Height]="120"></content-loader>
              <br />
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <!-- ************************** Footer commande ****************************-->

  <div class="container-fluid size-police-content-global-command background-color-content-global-command">
    <div class="row mt-1 mb-1" *ngIf="
        CommandInfo &&
        CommandInfo.CmdHeader.PRECO_ETAT != '1' &&
        CommandInfo.CmdHeader.PRECO_ETAT != '9' &&
        CommandInfo.CmdHeader.PRECO_ETAT != '3'
      " style="z-index: 99999">
      <div class="col-lg-2 margin-left-button-add-row">
        <button (click)="AddLineCmd(CommandInfo.CmdLine.length)" mat-stroked-button class="button-generic-add"
          type="button">
          <i class="fas fa-plus"></i> {{ "AddArticle" | translate }}
        </button>
      </div>
    </div>
    <hr class="mt-1" *ngIf="
        CommandInfo &&
        CommandInfo.CmdHeader.PRECO_ETAT != '1' &&
        !isImportCatalog
      " />
    <div class="d-flex justify-content-between">
      <div style="width: 300px">
        <div class="row-total-global-command display-table-row">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "OrderNumber" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell white-space-nowrap">
            {{
            this.CommandInfo &&
            this.CommandInfo.CmdHeader &&
            this.CommandInfo.CmdHeader.PRECO_IUCOM
            }}</span>
        </div>
        <div class="odd-row-total-global-command display-table-row">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "MarketNumber" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell white-space-nowrap"></span>
        </div>
        <div class="row-total-global-command display-table-row">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "OrderOfDate" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell white-space-nowrap">
            {{ GetDateCmd() }}</span>
        </div>
        <div class="odd-row-total-global-command display-table-row">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "ChangeOfDelivery" | translate }} :
            &nbsp;
          </span>
          <span class="float-end display-table-cell white-space-nowrap">{{
            DatelivreEdit && DatelivreEdit | date: "dd/MM/yyyy"
            }}</span>
        </div>
      </div>

      <div class="px-2" style="bottom: 0; width: 250px">
        <label class="font-weight-700">{{ "Notebook" | translate }}</label>
        <mat-form-field appearance="fill">
          <textarea class="height-commentary-global-command" matInput placeholder=""
            formControlName="NoteCmd"></textarea>
        </mat-form-field>
      </div>

      <div style="width: 300px">
        <div class="row-total-global-command display-table-row float-end">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "LinesNumber" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell white-space-nowrap">{{
            CommandInfo && CommandInfo.CmdLine.length
            }}</span>
        </div>
        <div class="odd-row-total-global-command display-table-row float-end">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "TotalExclTax" | translate }} : &nbsp;
          </span>
          <span class="float-end font-weight-700 display-table-cell">{{
            MtnTotalHT | currency: "EUR":"symbol":"1.2-2"
            }}</span>
        </div>
        <div class="row-total-global-command display-table-row float-end">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "TotalVAT" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell">{{
            MtnTotalTva | currency: "EUR":"symbol":"1.2-2"
            }}</span>
        </div>
        <div class="odd-row-total-global-command display-table-row float-end">
          <span class="font-weight-700 display-table-cell white-space-nowrap">{{ "TotalInclTax" | translate }} : &nbsp;
          </span>
          <span class="float-end display-table-cell">{{
            MtnTotalTTC | currency: "EUR":"symbol":"1.2-2"
            }}</span>
        </div>
        <div class="float-end button-footer-form mt-4 mb-2" *ngIf="
            CommandInfo &&
            CommandInfo.CmdHeader.PRECO_ETAT != '1' &&
            CommandInfo.CmdHeader.PRECO_ETAT != '9' &&
            CommandInfo.CmdHeader.PRECO_ETAT != '3'
          ">
          <button mat-stroked-button class="button-generic-validate mt-2" type="submit">
            {{ "Save" | translate }}
          </button>
          &nbsp;&nbsp;
          <button mat-stroked-button class="button-generic-cancel mt-2" type="button" (click)="Annuler('')">
            {{ "Cancel" | translate }}
          </button>
        </div>
        <div class="float-end mt-4 mb-2 button-footer-form" *ngIf="
            CommandInfo &&
            (CommandInfo.CmdHeader.PRECO_ETAT == '1' ||
              CommandInfo.CmdHeader.PRECO_ETAT == '9' ||
              CommandInfo.CmdHeader.PRECO_ETAT == '3')
          ">
          <button mat-stroked-button class="button-size button-action-color-blue mt-2" type="button"
            (click)="Annuler('back')">
            <i class="fas fa-arrow-left"></i> {{ "Back" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<Spinner-Loader class="spinner-loader-custom" [color]="colorLoader" [mode]="modeLoader" [value]="valueLoader"
  [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="displayProgressSpinner">
</Spinner-Loader>